// Webpack Imports
import * as bootstrap from 'bootstrap';


import gsap from "./gsap.min.js";
import owlcarousel from "./owl.carousel.min.js";



let refresh_timer = "";

jQuery(document).ready(function($) {

    // Get the anchor element
    var anchor = jQuery("#back_to_top");

    // When the user scrolls down 100px, show the anchor
    /*jQuery(window).scroll(function() {
        if (jQuery(this).scrollTop() > 100) {
            anchor.fadeIn();
        } else {
            anchor.fadeOut();
        }
    });
    */

    // When the user clicks on the anchor, scroll to the top of the page
    anchor.click(function() {
        jQuery("html, body").animate({scrollTop: 0}, "slow");
    });


// mobile menu
    $(".ico_burger").click(function (e) {
        $("body").toggleClass("noscroll open");

        $("#menu_mobile").toggleClass("nav-open");
        $(".navbar .menu-item").each(function(){
            $(this).toggleClass("nav-link-open");
        });

        $('header').toggleClass('open');
        return false
        e.stopPropagation()
    })


    $("#mobile_menu li .sub-menu a, #menu_mobile p.button a").click(function(e){
        if($("body").hasClass("home")){
            e.preventDefault();
            $(".ico_burger").trigger("click");
            //alert('laaa');
            let heightHeader = $("header").outerHeight(true) - 10;
            let dest = $(this).attr("href");
            let hash = dest.split('#')[1];
            //console.log(hash);
            TweenMax.to('html, body', .5, { scrollTop: $("#"+hash).offset().top - heightHeader , ease: Power4.easeInOut } );
        }else{
            // go to link
        }

    });
});










